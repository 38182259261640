/**
 * Expandable table of sites with robot data
 */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Button, Container, Typography, Tooltip } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions
} from 'mui-datatables';
import cx from 'classnames';
import moment from 'moment';

import { RobotEntity, SiteRobotInfo } from '../lib/types';
import { formatTime, formatTimeToday, formatTimeLocale, renderSiteLink } from '../lib/utils';
import { StoreState } from '../lib/redux';
import { commonStyles } from '../lib/styles';

import { FiberManualRecord } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// Cell properties to make the column
const MIN_CELL = () => ({ style: { width: '100px', minWidth: '50px' }});
const WIDE_CELL = () => ({ style: { width: '150px', minWidth: '50px' }});

const styles = commonStyles;

/** Table row */
export type Row = SiteRobotInfo;

// Connect to Redux
const connector = connect(
  // State
  (state: StoreState) => ({
    prefs: state.prefs,
  }),
)

type Props = {
  /** The rows to display in the table */
  rows: Row;
} & WithStyles<typeof styles> & ConnectedProps<typeof connector>;

type State = {
  /** Is the table opened? */
  open: boolean;
  /** Saved column filters */
  filters: Record<string, string[]>;
  robots: RobotEntity[],
  hideOfflineRobotOption: boolean
}

class RobotProfileTable extends React.Component<Props, State> {
  state: State = {
    open: false,
    robots: [],
    hideOfflineRobotOption: false,
    filters: {},
  }

  constructor(props: Props) {
    super(props);
    // Load the saved column filters
    this.state.filters = this.loadFilters();
    this.state.hideOfflineRobotOption = false;
    this.state.robots = this.props.rows.robots;
  }

  componentDidUpdate(prev: Props) {
    if (prev.rows !== this.props.rows) {
      this.setState({robots: this.props.rows.robots});
      this.state.hideOfflineRobotOption = false;
    }
  }

  render() {
    const { rows } = this.props;
    const { open } = this.state;
    const HeaderElements = () => (
      <>
        {this.state.open ? <Button onClick={this.filterRobotsByStatus}>{!this.state.hideOfflineRobotOption ? "hide offline robots" : "show offline robots"}</Button> : null}
      </>
    );
    // Prepare the table
    const columns  = this.prepareColumns();

    // Apply the saved column filters
    this.applyFilters(columns);

    // Override table components to hide all but the toolbar when not open
    const components = open ? {} : {
      TableBody: () => null,
      TableHead: () => null,
      TableFilterList: () => null,
    };

    const options: MUIDataTableOptions = {
      // Remove the box shadow
      elevation: 0,
      pagination: false,

      customToolbar: () => <HeaderElements />,
      // No reason to select the rows
      selectableRows: 'none',
      // Don't stack the cells for narrow windows
      responsive: 'standard',
      // Don't allow selecting columns
      viewColumns: false,

      // Manage the CSV download
      downloadOptions: {
        filterOptions: {
          // Only download the rows that match the filter
          useDisplayedRowsOnly: true,
        }
      },
      // Manage the filtering
      onFilterChange: this.filterUpdater(columns),
      textLabels: {
        body: {
          noMatch: !rows.robots.length ? 'There is no robot data for this site.' : ""
        },
      }
    };

    // Assemble the table title
    const icon = this.state.open ? <ExpandLessIcon/> : <ExpandMoreIcon/>;
    const onlineRobotsCount = rows.robots.filter(r => r.isRobotOnline).length;
    const totalRobotsCount = rows.robots.filter(r => !r.isRobotDisabled).length;
    const title =(
      <Typography classes={{ root: this.props.classes.title }} variant="h6" onClick={this.toggleTable}>
        {icon} {renderSiteLink(rows)} {totalRobotsCount > 0 ? `(${onlineRobotsCount}/${totalRobotsCount})` : '(No Robot Data)'}
      </Typography>
    );

    return (
      <Container className={cx(this.props.classes.table, {'open': open})} maxWidth={false}>
        <MUIDataTable title={title} options={options} columns={columns} data={this.state.robots} components={components} />
      </Container>
    );
  }

  /**
   * Toggle the table visibility
   */
  toggleTable = () => {
    this.setState((state) => ({
      open: !state.open
    }));
  }

  /**
   * Filter the rows based on the robot online or offline flag
   *
   * @returns rows matching the isRobotOnline flag
   */
  filterRobotsByStatus = () => {
    const { rows } = this.props;
    const hideOfflineRobots = !this.state.hideOfflineRobotOption;
    const filteredRobots = rows.robots.filter(r => hideOfflineRobots ? r.isRobotOnline : true);
    this.setState({robots: filteredRobots, hideOfflineRobotOption: hideOfflineRobots})
  }

  /**
   * Prepare the table column definitions
   *
   * Note: Please update downloadEntity() if the column order is changed.
   *
   * @param rows all the data rows
   * @returns table column definitions
   */
  prepareColumns = (): MUIDataTableColumn[] => {
    // Get the start of today for timestamp formatting
    const today = this.getToday();

    return [
      { // Raw entity to help with CSV download - see downloadCsv()
        name: 'robots',
        options: {
          display: 'excluded',
          download: false,
          filter: false,
          searchable: false
        }
      },
      {
        label: 'online',
        name: 'isRobotOnline',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: false,
          filter: true,
          searchable: true
        }
      },
      {
        label: 'Serial Number',
        name: 'entityId',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: true,
          filter: true,
          searchable: true,
          customBodyRenderLite: this.renderLite(this.state.robots, this.renderRobotSerialWithStatus)
        }
      },
      {
        label: 'Name',
        name: 'entityName',
        options: {
          setCellProps: WIDE_CELL,
          setCellHeaderProps: WIDE_CELL,
          display: true,
          filter: true,
          searchable: true
        }
      },
      {
        label: 'Operational Status',
        name: 'robotOperationalState',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: true,
          filter: true,
          searchable: true,
          customBodyRenderLite: this.renderLite(this.state.robots, r => r.robotOperationalState ? r.robotOperationalState : "-")
        }
      },
      {
        label: 'Availability',
        name: 'isRobotAvailable',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: true,
          filter: true,
          searchable: true,
          customBodyRenderLite: this.renderLite(this.state.robots, this.renderRobotNameWithAvailabilityStatus)
        }
      },
      {
        label: 'No.of alerts',
        name: 'numAlert',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: true,
          filter: true,
          searchable: true
        }
      },
      {
        label: 'Last Online',
        name: 'robotLastOnline',
        options: {
          setCellProps: MIN_CELL,
          setCellHeaderProps: MIN_CELL,
          display: true,
          filter: true,
          searchable: true,
          customBodyRenderLite: this.renderLite(this.state.robots, r => r.isRobotOnline ? "Online" : this.renderTimestamp(r.robotLastOnline, today))
        }
      },
      {
        label: 'Claimed By',
        name: 'claimedBy',
        options: {
          setCellProps: WIDE_CELL,
          setCellHeaderProps: WIDE_CELL,
          display: true,
          filter: true,
          searchable: true,
          customBodyRenderLite: this.renderLite(this.state.robots, r => r.claimedBy ? r.claimedBy : "N/A")
        }
      },
    ];
  }

  /**
   * Wrapper that finds the original data row and renders a field.
   *
   * @param rows all the rows in the table
   * @param callback function that renders the field
   * @returns wrapper for customBodyRenderLite
   */
  renderLite = (rows: RobotEntity[], callback: (row: RobotEntity) => any) => {
    return (dataIndex: number, _rowIndex: number) => {
      const row = rows[dataIndex] as RobotEntity;
      if (!row) {
        return null;
      }

      return callback.call(null, row);
    };
  }

  /**
   * Find the start of the current day
   *
   * @returns the start of the day, as a Moment
   */
  getToday = () => moment().startOf('date');

  /**
   * Render a timestamp with a tooltip based on the user preferences
   */
  renderTimestamp = (time: any, today: moment.Moment) => {
    const { prefs } = this.props;

    // Format the time based on the user preferences
    const text = prefs.todayTime
      ? formatTimeToday(time, today)
      : formatTime(time);
    return (
      <Tooltip title={formatTimeLocale(time)}>
        <span>{text}</span>
      </Tooltip>
    );
  }

  /**
   * Render the robot with online/offline status for the row
   *
   * @param row table row
   * @returns Rendered button
   */
  renderRobotSerialWithStatus = (row: RobotEntity) => {
    // Note: the extra <span> is to work around tooltips not appearing on disabled elements.
    let tooltip = row.isRobotOnline ? "Online" : "Offline";
    return (
      <Tooltip title={tooltip}>
        <span><FiberManualRecord className={row.isRobotOnline ? 'online' : 'offline'} /> {row.entityId}</span>
      </Tooltip>
    );
  }

  /**
   * Render the robot with availability status for the row
   *
   * @param row table row
   * @returns Rendered icon
   */
  renderRobotNameWithAvailabilityStatus = (row: RobotEntity) => {
    let tooltip = row.isRobotAvailable ? "Available" : "Unavailable";
    const icon = row.isRobotAvailable ? <CheckCircleIcon/> : <CancelIcon/>;
    return (
      <Tooltip title={tooltip}>
        <span>{icon}</span>
      </Tooltip>
    );
  }

  /**
   * Build the local storage key for a type of data
   *
   * @param data_type type of data (filters, etc)
   * @returns key into local storage
   */
  makeStorageKey = (data_type: string): string => {
    return `robots.${this.props.rows.siteId}_robot_profile.${data_type}`;
  }

  /**
   * Load the filter list values from local storage
   *
   * @returns filter lists
   */
  loadFilters = (): Record<string, string[]> => {
    // Fetch the saved JSON value from local storage
    const saved = localStorage.getItem(this.makeStorageKey('filters'));
    return saved ? JSON.parse(saved) : {};
  }

  /**
   * Apply the filter lists to the columns
   *
   * @param columns column definitions
   */
  applyFilters = (columns: MUIDataTableColumn[]) => {
    const { filters } = this.state;

    for (const column of columns) {
      if (!column.options) {
        // Make sure the column has options
        column.options = {}
      }

      // Apply the filter list from saved state
      column.options.filterList = filters[column.name];
    }
  }

  /**
   * Build a wrapper to manage column filters
   *
   * @param columns column definitions
   * @returns wrapper to update the filter state
   */
  filterUpdater = (columns: MUIDataTableColumn[]) => {
    // Get the key for the local storage
    const key = this.makeStorageKey('filters');

    // Build the wrapper
    return (_changedColumn: any, filterList: string[][], _type: any, _changedColumnIndex: number, _displayData: any) => {
      // New filter state
      const filters: Record<string, string[]> = {};

      // Add each column's filter list
      columns.forEach((c, idx) => filters[c.name] = filterList[idx]);

      // Save in local storage for next time
      localStorage.setItem(key, JSON.stringify(filters));
      // Save in component state for now
      this.setState({ filters })
    };
  }
}

export default connector(
  withStyles(styles)(RobotProfileTable)
);